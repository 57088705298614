import React from 'react';

const SVG = () => {
  return (
    <svg width="120" height="100" xmlns="http://www.w3.org/2000/svg">
      <line x1="75" x2="90" y1="5" y2="5" style={{ stroke: '#5c2fff', strokeWidth: '1' }} />

      <line x1="75" x2="75" y1="5" y2="75" style={{ stroke: '#5c2fff', strokeWidth: '1' }} />

      <line x1="55" x2="75" y1="75" y2="75" style={{ stroke: '#5c2fff', strokeWidth: '1' }} />

      <circle cx="95" cy="5" r="4"  fill="#5c2fff"></circle>
    </svg> );
};

export default SVG;
